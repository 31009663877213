<template>
    <div>
		<Button label="Print" icon="pi pi-print" @click="printClick" style="display: block; margin-bottom: 20px; margin-left: 6px" :key="1"></Button>

        <div class="grid invoice-body">
            <div class="col-12">
                <div id="invoice-content">
                    <div class="card invoice-wrapper">
                        <div class="grid mx-0 invoice-header">
                            <div class="col-6 logo-adress">
                                <img src="layout/images/extensions/prime-logo.svg" alt="avalon-layout"/>
                                <p>9137 3rd Lane California City, CA 93504.</p>
                            </div>
                            <div class="col-6 title-date">
                                <h1 class="title">INVOICE</h1>
                                <p>30/08/2019</p>
                            </div>
                        </div>
                        <div class="card invoice-table billto-table">
                            <div class="grid table-header">
                                <div class="col-4">
                                    <h2 class="header">BILL TO </h2>
                                </div>
                                <div class="col-3">
                                    <h2 class="header">DATE </h2>
                                </div>
                                <div class="col-2 text-right">
                                    <h2 class="header">INVOICE NO </h2>
                                </div>
                                <div class="col-3 text-right">
                                    <h2 class="header">NOTES </h2>
                                </div>
                            </div>
                            <div class="grid table-content-row">
                                <div class="col-4">
                                    <p>TOYOKSU SYSCOM CORPORATION 11-27, MEIEKI 4-CHROME
                                        NAKAMURA-KU, NAGOYA 450-0002 JAPAN </p>
                                </div>
                                <div class="col-3">
                                    <p>30/08/2019 </p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>A/3100 </p>
                                </div>
                                <div class="col-3 text-right">
                                    <p>N/A </p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="card invoice-table products-table">
                            <div class="grid mx-0 table-header">
                                <div class="col-5 row-title">
                                    <h2 class="header">DESCRIPTION </h2>
                                </div>
                                <div class="col-2 text-right">
                                    <h2 class="header">QUANTITY </h2>
                                </div>
                                <div class="col-2 text-right">
                                    <h2 class="header">UNIT PRICE </h2>
                                </div>
                                <div class="col-3 text-right">
                                    <h2 class="header">LINE TOTAL </h2>
                                </div>
                            </div>
                            <div class="grid mx-0 table-content-row even">
                                <div class="col-5 row-title">
                                    <p>1 Year PrimeFaces Elite Subscription </p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>4 </p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>$99.00</p>
                                </div>
                                <div class="col-3 total text-right">
                                    <p>$396.00 </p>
                                </div>
                            </div>
                            <div class="grid mx-0 table-content-row">
                                <div class="col-5 row-title">
                                    <p>PrimeFaces Ultima Extended License </p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>1</p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>$790.00</p>
                                </div>
                                <div class="col-3 total text-right">
                                    <p>$790.00 </p>
                                </div>
                            </div>
                            <div class="grid mx-0 table-content-row even">
                                <div class="col-5 row-title">
                                    <p>PrimeFaces Prestige Basic License </p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>2</p>
                                </div>
                                <div class="col-2 text-right">
                                    <p>$59.00</p>
                                </div>
                                <div class="col-3 total text-right">
                                    <p>$118.0</p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="card invoice-table bank-total-table">
                            <div class="grid mx-0 table-header">
                                <div class="col-6 text-left ">
                                    <h2 class="header">BANK </h2>
                                </div>
                                <div class="col-6 text-right">
                                    <h2 class="header">TOTAL </h2>
                                </div>

                            </div>
                            <div class="grid mx-0 table-content-row">
                                <div class="col-2 row-title">
                                    <h2>Bank</h2>
                                </div>
                                <div class="col-3 text-left">
                                    <p>BestBank </p>
                                </div>
                                <div class="col-5 text-right total">
                                    <h2>Subtotal</h2>
                                </div>
                                <div class="col-2 total text-right">
                                    <p>$1304.00</p>
                                </div>
                            </div>
                            <div class="grid mx-0 table-content-row even">
                                <div class="col-2 row-title">
                                    <h2>Account Beneficiary</h2>
                                </div>
                                <div class="col-3 text-left">
                                    <p>Edward Williams</p>
                                </div>
                                <div class="col-5 text-right total">
                                    <h2>VAT</h2>
                                </div>
                                <div class="col-2 total text-right">
                                    <p>$234.72</p>
                                </div>
                            </div>
                            <div class="grid mx-0 table-content-row">
                                <div class="col-2 row-title">
                                    <h2>Swift</h2>
                                </div>
                                <div class="col-3 text-left">
                                    <p>PJNWBXND</p>
                                </div>
                                <div class="col-5 text-right total ">
                                    <h2>Total</h2>
                                </div>
                                <div class="col-2 total text-right">
                                    <p>$1538.72</p>
                                </div>
                            </div>
                            <div class="grid mx-0 table-content-row even">
                                <div class="col-2 row-title">
                                    <h2>IBAN</h2>
                                </div>
                                <div class="col-10 text-left">
                                    <p>GB04420235692263866724650931</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>	
</template>
    
<script>
export default {
	methods: {
		printClick() {
			window.print();
		}
	}
}
</script>

